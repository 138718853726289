<script>
import { createEventDispatcher } from "svelte";
const dispatch = createEventDispatcher();
export let largeIcon = false;
export let noText    = false;
</script>


<button class="PlayButton" on:click={() => dispatch("play")}>
  <i class:large-icon={largeIcon}></i><span class:fat={largeIcon} class:no-text={noText}><slot /></span>
</button>


<style lang="scss">

span {
  color:       white;
  font-size:   1.625rem;
  font-family: $font_reg;
  margin-left: 0.875rem;

  &.fat {
    font-weight: bold;
  }

  &.no-text {
    margin-left: 0;
  }
}

i {
  background-color: $text-hl;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: background-color, transform;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: calc(50% + 8%);
    width: 13px;
    height: 10px;
    background-image: url('/images/play.svg');
    background-repeat: no-repeat;
    transition: 0.3s ease transform;
  }

  &:before { transform: translate(-50%, -50%); }
  &:after  { transform: translate(calc(-50% - 220%), -50%); }

  width:  30px;
  height: 30px;

  &.large-icon {
    width:  52px;
    height: 52px;
  }

  &.large-icon:before, &.large-icon:after {
    width: 27px;
    height: 21px;
  }
}

button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  padding: 0;

  @include desktop {
    &:hover {
      i {
        background-color: white;

        &.large-icon {
          transform: scale(1.1);
        }

        &:before { transform: translate(calc(-50% + 220%), -50%); }
        &:after  { transform: translate(-50%, -50%); }
      }
    }
  }
}

</style>
