<script context="module">
  const modals = {}; // all modals get registered here for easy future access
  let   current;     // keeping track of which open modal is on top

  const closeSvg = `
    <svg width="28px" height="30px" viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg">
      <g stroke-width="2" stroke="#e16c49">
        <line x1="1" y1="1"  x2="25" y2="25" />
        <line x1="1" y1="25" x2="25" y2="1"  />
      </g>
    </svg>
  `;

  //  returns an object for the modal specified by `id`, which contains the API functions (`open` and `close` )
  export const getModal = (id = '') => modals[id];
</script>

<script>
  import { onDestroy } from "svelte";
  import { browser } from "$app/environment";

  // Dom
  let thisModal;

  // State
  let visible = false;
  let previous;

  // Props
  export let id = '';
  export let onOpen = () => {};
  export let onClose = () => {};

  // Functions
  function keyPress (ev) {
    if (ev.key == "Escape" && current == thisModal) close();
  }

  function open () {
    if (visible) return;
    previous = current;
    current  = thisModal;
    visible  = true;

    onOpen(thisModal);
    document.body.style.overflow = "hidden";
    document.body.appendChild(thisModal);
    browser && window.addEventListener("keydown", keyPress);
  }

  function close (returned) {
    if (!visible) return;
    visible = false;
    current = previous; // Pop stack

    if (current == null) { document.body.style.overflow = ""; }
    onClose(returned);
    browser && window.removeEventListener("keydown", keyPress);
  }

  // Add modal to master list by id (must be unique)
  if (modals[id]) {
    console.error(`Modal::init - modal [${id}] is already registered.`);
  } else {
    modals[id] = { open, close };
  }

  // Hooks
  onDestroy(() => {
    delete modals[id];
    browser && window.removeEventListener("keydown", keyPress);
    browser && (document.body.style.overflow = "");
  });
</script>


<div role="dialog" class="ModalOverlay" bind:this={thisModal} class:visible on:click={close}>

  <button class="ModalClose" aria-label="Close Modal" on:click={close}>
    { @html closeSvg }
  </button>

  <div class="ModalWrapper" role="dialog" on:click|stopPropagation={()=>{}}>
    {#if visible}
      <div class="ModalContent">
        <slot></slot>
      </div>
    {/if}
  </div>
</div>


<style lang="scss">
  .ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.0);
    padding: 60px 40px;

    @include tablet { padding: 40px 30px; }
    @include mobile { padding: 20px 0; }

    visibility: hidden;
    opacity: 0.4;
    transition: visibility 0.3s, opacity 0.3, background 0.3;

    &.visible {
      visibility: visible !important;
      opacity: 1;
      background: rgba(black, 0.9);
    }
  }

  .ModalClose {
    cursor:  pointer;
    border:  0px;
    padding: 10px;
    background-color: transparent;

    position: absolute;
    top:     30px;
    right:   30px;
    z-index: 1;

    @include mobile { top: 0; right: 0; }

    opacity: 1;
    transition: opacity 0.3s ease 0s;
    &:hover { opacity: 0.5; }
  }

  .ModalWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90vw;
    max-height: 90vh;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    align-items: center;
  }

  .ModalContent {
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    max-height: 100%;
    height: 100%;
  }
</style>
