<script>
import VimeoPlayer from "$lib/components/VimeoPlayer.svelte";
import Modal       from "$lib/components/Modal.svelte";

const { round } = Math;

export let id;
export let video;
export let controls = false;

export let onClose = (it) => it;
</script>


<Modal {id} {onClose}>
  <div class="vimeo-modal-wrapper">
    <div class="vimeo-aspect-control">
      <VimeoPlayer videoId={video.vid} autoplay {controls} aspect={video.width / video.height} />
    </div>

    <div class="vimeo-aux-content">
      <slot />
    </div>
  </div>
</Modal>


<style lang="scss">

  :global(.vimeo-modal-wrapper) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  :global(.vimeo-aspect-control) {
    position: relative;
    margin: 0 auto;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  :global(.VimeoPlayer) {
    max-height: 100%;
  }

</style>
